.navbar{
    background-color: white;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px 0 0px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  top: 0;
  width: 100%;
  left:0px;
  z-index: 1;
  background-image: linear-gradient(to right,#2b80ec,#1d1f33);

}
.dontwrap{
    display: flex;
    flex-wrap: nowrap;
}
#divToPrint2{
    min-width: 300px;
    max-height: 500px;
    font-size: 16px;
}
.offline{
    position: absolute;
    top: 35px;
   right: 0px;
    background-color: rgb(236, 188, 188);
    border-radius: 5px;
    padding: 4px;
    border: 1px solid rgb(179, 43, 43);
    font-size: 15px;
    width: 165px;
    font-family: 'Times New Roman';
}
.req{
    position: absolute;
    top: -10px;
    left: -50px;
    color: #ffffff;
   display: none;
}
.mgn:hover .req{
    display: block;
}
.wticon{
    color: #fff;
}
.searchwidth{
    width: 100%;
}
.powered{
    font-size: 9px;
}
.noti{
    position: absolute;
    margin-right: 100px;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    padding-left: 5px;
    width: 15px;
    height: 15px;
}
.product3{
    font-size: 100px;
    color: red;
    color: #fff;
    background-color: #5cb85c;
}
.iconb{
   display: flex;
   justify-content: center;
}
.navbtn{
    height: 50px;
    cursor: pointer;
    margin: -5px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #5cb85c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-color: #2dce89;
    
}

.navbar1{
    background-color: white;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  top: 0;
  width: 100%;
  left:0px;
  z-index: 1;
  background-image: linear-gradient(to right,#2b80ec,#1d1f33);

}
.usericon{
    font-size: 18px;
}
.mobilebtn{
    color: white;
    font-size: 25px;
}
.nav_icon{
    display: none;
}
.nav_icon > i{
    font-size: 26px;
    color: #a5aaad;
}
.navbar_left > a{
margin-right: 30px;
text-decoration: none;
color: #a5aaad;
font-size: 15px;
font-weight: 700;
}
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_right{
        display: flex;
       position: absolute;
       right: 250px;
        }
        .navbar_right1{
            display: flex;
            align-items: center;
           position: fixed;
           right: 10px;
           color: white;
           
            }
 .navbar_right a{
        margin-left: 20px;
        text-decoration: none;
}
.navbar_right2{
    display: flex;
   position: absolute;
   left: 20%;
   color: white;
   text-align: center;
   align-items: center;
   width: 100%;
   padding-top: 5%;
   z-index: -1;
    }
.navbar_right a i{
    font-size: 16px;
    border-radius: 50px;
    color: #fff;
    background-color: #2dce89;
    box-shadow: 2px 2px 5px #d9d9d9 -2px -2px 5px #ffffff;
    padding: 7px;
}
.adminmenu1{
    box-shadow: #0000003d 0px 3px 8px;
   padding: 4px;
    position: fixed;
    right: 15px;
    top: 60px;
    background-color: white;
    height: 100px;
    border-radius: 5;
}
.setmyw{
    width: 300px;
    font-family: Tahoma;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
margin-left: auto;
margin-right: auto;
text-align: center;
}
.adminactive{
    display: none;
    background-color: black;
    padding-bottom: 2px;
}
@media screen and (max-width: 978px){
    .nav_icon{
       display: inline;
    } 
 }
 @media screen and (max-width: 960px){
    .navbar_left{
       display: none;
    } 
    .navbar{
        background: #1E90FF;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-bottom: 1px solid lightgrey;
        position: fixed;
      top: 0;
      width: 100%;
      left:0px;
      margin-bottom: 20px;
    }
    .navbar1{
        background: #1E90FF;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-bottom: 1px solid lightgrey;
        position: fixed;
      top: 0;
      width: 100%;
      left:0px;
      margin-bottom: 20px;
    }
   
    .navbar_right{
        display: flex;
       position: absolute;
       right: 5%;
        }
        .navbar_right1{
            display: flex;
           position: absolute;
           right: 5%;
            }
        .usericon{
            font-size: 18px;
            color: white;
        }
        .navbar_right{
           display: none;
            }
            .navbar_right2{
                display: flex;
               position: absolute;
               left: 20%;
               top: 5%;
               color: white;
               align-items: center;
               width: 100%;
               padding-top: 5%;
               z-index: -1;
                }
 }