.ordersnumber{
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  .row{
    margin-top: 0%;
    padding-top: 0%;
  }
  .divwrap{
    display: flex;
    flex-wrap: nowrap;
  }
  .floatright{
    float: right;
    margin-right: 10%;
  }
  .now{
    
    margin-top: 0%;
    padding-top: 0%;
    font-weight: bold;
    border-bottom: 1px solid grey;
  }
  .newwidth{
    width: 220px;
  }
  .totalborder{
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  .blackme{
    background-color: black;
  }
  .bodysection{
    margin-bottom: 100px;
    position:relative;
    top: 10%;
    left: 120px;
    
    margin-top:1%;
    background-color: white;
    padding: 0;
    clear: both;
    box-shadow: 0 3px 10px;
    border-radius: 0.25rem;
  }
  .bodysection3{
    position: relative;
    top: 10%;
    left: 2%;
    margin-left:7%;
    margin-top:2%;
    padding-left: 2%;
    margin-bottom: 5%;
    clear: both;
    margin-bottom: 100px;
  }
  .bodysection4{
    clear: both;
    position: relative;
    top: 10%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
  }
  .bodysection2{
    position: fixed;
    overflow-y: auto;
    top: 15%;
    left: 14%;
    margin-left:5%;
    margin-top:1%;
    clear: both;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
   
  }
  .bodysection7{
    position: fixed;
    height: 280px;
    top: 15%;
    left: 14%;
    margin-left:5%;
    margin-top:1%;
    clear: both;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
   
  }
  .bodysection6{
    position: relative;
    top: 7%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    clear: both;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
   
  }
  .bodysection8{
    position: relative;
    top: 7%;
    clear: both;
    margin-left:-8%;
    margin-top:1%;
    background-color: white;
    padding: 0;
    margin-bottom: 5%;
    bottom:0;
    margin-bottom: 100px;
  }
  .bodysection80{
    position: relative;
    top: 7%;
    clear: both;
    margin-left:-110px;
    padding-left: -8%;
    margin-top:1%;
    background-color: white;
    padding: 0;
    margin-bottom: 5%;
    bottom:0;
    margin-bottom: 100px;
  }
  .cardheight{
    height:100px
  }
  .productparent{
   margin-right: 10%;
  }
  .textalign{
    text-align: right;
  }
  hr {
    border: none;
    border-top: 1px dashed black;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 100%;
  }
  .text-alignme{
    text-align: right;
  }
  .productsquare{
    cursor: pointer;
    max-width: 20%;
    min-width: 135px;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2px;
    margin-left: 2px;
    flex: 1;
    text-align: center;
  }
  .productouter{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .productname{
    
   width: 100%;
   display: flex;
    justify-content: center;
  }
  .rmleft{
    padding-right: 25px;
  }
  .nosize{
    max-width: 6px;
  }
  .newdiv{
    background-color: red;
    padding-left: 0px;
    margin-left: 0px;
  }
  .product{
    font-size: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .product2{
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .product3{
    font-size: 20px;
    width: 100%;
    display: flex;
    height: 20px;
    justify-content: center;
    color: red;
  }
  .divborder1{
    border: 1px solid #E5E4E2;
   
  }
  .ldiv{
    border-right: 1px solid grey;
  }
  .cborder{
   border: none;
   background-color: transparent;
  }
  .bborder{
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }
  .minqty{
    min-width: 100px;
  }
  .product1{
font-size: 25px;
  }
  .price{
   
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .path{
    position: relative;
    top: 25%;
    left: 9%;
    margin-top: 2%;
  }
  .bodysection1{
    position: absolute;
    top: 15%;
    left: 7%;
    margin-left:5%;
   
  }
  .orderscol{
    box-shadow: #0000003d 0px 3px 8px;
    border-radius: 5px;
    height: 100px;
    margin-left: 2%;
    margin-top: 10px;
    max-width: 220px;
    background-color: white;
   
  }
  .salesdiv{
    border-left: 3px solid #72A0C1;
  }
  .salestext{
    color:#72A0C1 ;
  }
  .stockdiv{
    border-left: 3px solid #1560bd;
  }
  .stocktext{
    color:#1560bd ;
  }
  .usersdiv{
    border-left: 3px solid #1034A6;
  }
  .userstext{
    color:#1034A6 ;
  }
  .itemsdiv{
    border-left: 3px solid #008E97;
  }
  .itemstext{
    color:#008E97 ;
  }
  .orderscol1{
    box-shadow: #0000003d 0px 3px 8px;
    border-radius: 5px;
    height: 100px;
    margin-left: 2%;
    margin-top: 10px;
    min-width: 100px;
    background-color: white;
  }
  .ordersrow{
width: 175px;
margin-left: 10px;
padding-bottom: 5px;
  }
  .ordersrow1{
    min-width: 175px;
    margin-left: 10px;
    padding-bottom: 5px;
      }
  .col1{
    max-width: 200px;
  }
  .submenu{
    background-color: grey;
    padding: 5px;
  }
  .invdetails{
    display: flex;
    justify-content: space-between;
    
  }
  .rowbd{
    border: 1px solid grey;
    display: flex;
    flex-wrap: nowrap;
  }
  .rowbd1{
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
  }
  .rowbd2{
    border-bottom: 1px solid grey;
   
  }
  .allcont{
    border: 1px solid grey;
  }
  .printme {
    display: none;
  }
  @media print {
    .no-printme  {
      display: none;
    }
    .printme  {
      display: block;
    }
  }
  .testmenow{
    background-color: black;
    z-index: -1; 
  }
  .stbg{
    background-color: #72A0C1;
    color: white;
    font-size: 35px;
    margin-left: 2px;
  }
  .stbg1{
    background-color: #1560bd;
    color: white;
    font-size: 35px;
    margin-left: 2px;
  }
  .stbg2{
    background-color: #008E97;
    color: white;
    font-size: 35px;
  }
  .stbg3{
    background-color: #1034A6;
    color: white;
    font-size: 35px;
  }
  .dots{
    padding: 3px;
    height: 28px;
    display: flex;
    align-items: center;
   margin-top: 3px;
   cursor: pointer;
   position: sticky;
  }
  .wrapper {
    position: relative;
  }
  .fixwidth{
    height: 30px;
    float: right;
}
.fixwidth1{
  height: 30px;
  padding-top: 2px;
}
.solo{
  display: block;
  margin-top: -10px;
}
.setbg{
  background-color: rgb(0, 21, 41);
  color: white;
}
.dotsdiv{
  box-shadow: #0000003d 0px 3px 8px;
  position: absolute;
  right: 0px;
  top: 22px;
  height: 100px;
  display: none;
}
.dotsdivactive{
  
  position: absolute;
  right: -20px;
  top: -3px;
 
  height: 100px;
  display: block;
}
.ulpadding{
  padding: 3px;
  text-decoration: none;
  cursor: pointer;
}
.uldiv{
  padding: 3px;
  text-decoration: none;
  box-shadow: #0000003d 0px 3px 8px;
  background-color: #ffffff;
  position: relative;
  right: 0px;
  top: -3px; 
  cursor: pointer;
  width: 100px;
  z-index: 10;
  text-decoration: none;
  box-shadow: #0000003d 0px 3px 8px;
}
.uldiv:hover{
  cursor: pointer;
}
.theader{
 
  background-color:#72A0C1 ;
}
  @media screen and (max-width: 960px) {
    .bodysection3{
      position: relative;
      top: 10%;
      left: -7%;
      margin-left:3%;
      margin-top:2%;
      padding-left: 2%;
      margin-bottom: 10%;
     
    }
    .bodysection1{
      position: absolute;
      top: 12%;
      left: -5%;
      margin-left:5%;
     
    }
    .bodysection{
      position: relative;
      top: 10%;
      clear: both;
      left: 0%;
      margin-left:0%;
    padding-left: 0%;
    margin-bottom: 100px;
    }
    
    .qtysize{
      width: 10px;
    }
    
    .bodysection6{
      position: relative;
      top: 10%;
      left: 0%;
      margin-left:0%;
    padding-left: 0%;
    }
    .bodysection2{
  
      margin-left:-10%;
      padding-left: 0%;
      width: 100%;
    }
    .bodysection2 input{
  
     width: 100%;
    
    }
    .mobilecenter{
      margin: 0 auto;
     text-align: center;
    }
    .mobile{
      display: none;
    }
    .mobilediv{
      padding-right: 20px;
    }
    .orderscol{
      box-shadow: #0000003d 0px 3px 8px;
      border-radius: 5px;
      height: 100px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      min-width: 300px;
    }
    .ordersrow{
      width: 100%;
      display: flex;
     justify-content: center;
     margin-right: 3px;
    }
    .orderscol1{
      box-shadow: #0000003d 0px 3px 8px;
      border-radius: 5px;
      height: 100px;
      
      margin-top: 10px;
      min-width: 300px;
    }
    .ordersrow1{
      width: 100%;
      margin-left: 0px;
    }
    .bodysection4{
      background-color: white;
      position: relative;
      top: 15%;
      left: 1%;
      margin-left:-1%;
      margin-top:2%;
      padding-left: 2%;
      margin-bottom: 5%;
     
    }
    .card-deck{
      justify-content: center;
      background-color: #D6EAF8;
      width: 100%;
    }
    .card{
      justify-content: center;
    }
    .theader{
      margin-top: 5%;
     
    }
    .productsquare{
      cursor: pointer;
      min-width: 125px;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2px;
      margin-left: 2px;
      flex: 1;
    }
    .salesdiv{
      border-left: 4px solid #72A0C1;
    }
    .salestext{
      color:#72A0C1 ;
    }
    .stockdiv{
      border-left: 4px solid #1560bd;
    }
    .stocktext{
      color:#1560bd ;
      
    }
    .usersdiv{
      border-left: 4px solid #1034A6;
    }
    .userstext{
      color:#1034A6 ;
    }
    .itemsdiv{
      border-left: 4px solid #008E97;
    }
    .itemstext{
      color:#008E97 ;
    }
    .bodysection80{
      position: relative;
      top: 7%;
      clear: both;
      margin-left:-8%;
      margin-top:1%;
      background-color: white;
      padding: 0;
      margin-bottom: 5%;
      bottom:0;
      margin-bottom: 100px;
    }
 }