.btn1{
    cursor: pointer;
    margin: 4px;
    padding: 0px;
    font-size: 14px;
  }
  .centermodal{
    margin-left: auto;
    margin-right: auto;
    width: 100%;

  }
  .modal1{
    width:50%;
    font-size: 18px;
    height: 450px;
    margin: 0 auto; 
    border-radius: 0%;
  }
  .check{
    margin: auto;
  }
  .progress{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    font-size: 12px;
    background-color: white;
    height: 30px;
    text-align: center;
    margin-bottom: 5px;
  }
  .modalheader{
    font-size: 12px;
    height: 20px;
   
  }
  .modalfooter{
    font-size: 12px;
    height: 10px;
  }
  .closebtn{
    height: 20px;
    width: 45px;
    font-size: 12px;
    padding-top: 0%;
    padding-left: 2%;
    margin-right: 1px;
    position: absolute;
    right: 1px;
   
  }
  .modalheader1{
    border: none;
    font-size: 12px;
    height: 20px;
  }
  .modalbody{
   
    font-size: 12px;
  }
  .green{
    color: green;
    font-size: 30px;
  }
  .red{
    color: red;
    font-size: 30px;
  }
  .border
  {
    border-left: 1px solid black;
    
  }
  .border1
  {
   border: none;
   padding-top: 1px;
    
  }
  