.footer{
    background-color: rgb(250, 246, 246);
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  bottom: 0;
  width: 90%;
  left:200px;
  z-index: 1;
  margin-top: 50px;
  clear: both;
}
.footer1{
    background-color: rgb(209, 204, 204);
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  bottom: 0;
  width: 100%;
  left:0px;
  z-index: 1;
  margin-top: 50px;
  clear: both;
}
.text-color{
    color: #1560bd;
}
.navbar_rightnew{
        display: flex;
     align-items: center;
     padding-top: 10px;
       position: absolute;
       right: 30%;
        }
        .navbar_rightnew1{
          display: flex;
       align-items: center;
       padding-top: 10px;
         position: absolute;
        right: 10%;
          }
 .navbar_right a{
        margin-left: 20px;
        text-decoration: none;
}
.navbar_right a i{
    color: #f3f3f3;
    font-size: 16px;
    border-radius: 50px;
   
    box-shadow: 2px 2px 5px #d9d9d9 -2px -2px 5px #ffffff;
    padding: 7px;
}
.adminmenu{
    box-shadow: #0000003d 0px 3px 8px;
    padding: 10px;
    position: absolute;
    right: 100px;
    top: 55px;
}
.adminactive{
    display: none;
    background-color: black;
}
@media screen and (max-width: 978px){
    .nav_icon{
       display: inline;
    } 
 }
 @media screen and (max-width: 960px){
    .navbar_left{
       display: none;
    } 
    .footer{
       margin-top: 5%;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-top: 1px solid lightgrey;
        position: fixed;
      bottom: 0;
      width: 100%;
      left:0px;
      
    }
    .adminmenu{
        box-shadow: #0000003d 0px 3px 8px;
        padding: 10px;
        position: absolute;
        right: 30px;
        top: 55px;
        background-color: white;
    }
    .navbar_rightnew{
       display: inline;
       width: 100%;
       left:0px;
        }
        .usericon{
            font-size: 18px;
            color: white;
        }
        .text-color{
            display: none;
        }
        .footer1{
            background-color: rgb(209, 204, 204);
            grid-area: nav;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px 0 30px;
            border-bottom: 1px solid lightgrey;
            position: fixed;
          bottom: 0;
          width: 100%;
          left:0px;
          z-index: 1;
          margin-top: 80px;
          clear: both;
        }
 }