.sidenav{
  
   width: 230px;
   box-shadow: #0000003d 0px 3px 8px;
   position: fixed;
   z-index: 99; 
   margin-left: 0%;
   color: white;
  top: 60px;
  min-height:100%;
   background-color: rgb(0, 21, 41);
   overflow-y:scroll;
    overflow-x:hidden;
    bottom:0;
  
}
.sidenav::-webkit-scrollbar-thumb:hover{
   background-color: grey;
border-radius:5px;
}
.sidenav::-webkit-scrollbar{
   width:10px;
   background-color: rgb(0, 21, 41);
   }
.toplogo{
   background-color: white;
   height: 60px;
   display: flex;
   justify-content: center;
   padding-bottom: 50px;
   display: none;
}
.toplogo1{
   background-color: white;
   height: 60px;
   display: flex;
   justify-content: center;
   padding-bottom: 50px;
   width: 230px;
}
.sidenav1{
  
   min-width: 200px;
   box-shadow: #0000003d 0px 3px 8px;
   position: fixed;
   z-index: 99; 
   margin-top: 0%;
   margin-left: 0%;
   color: white;
  top: 200;
  height:100%;
   background-color: rgb(0, 21, 41);
   overflow-y:scroll;
    overflow-x:hidden;
    bottom:0;
    display: none;
}
a{
   color:white
}
.mkwhite{
   color: white;
}
.jimslogo{
   padding-left: 13%;
   
   width: 200x;
}
.toggle1{
   margin-right: 50px;
}
.whiteicon{
   color: white;
}
.content{
   position: absolute;
   left:70px;
   top:50px;
}
.submenu{
   display: block;
   transition: 0.5s all;
}
.submenuhidden{
   display: none;
}
.invoice{
   cursor: pointer;
}
.updownicons{
   font-size: 15px;
}
a{
   text-decoration: none;
   color: black;
}
i{
   margin-right: 10px;
}
.activelink{
   background-color:#1E90FF;
   width: 100%;
   color: white;
   margin-left: 0%;
   margin-right: 0%;
}
.activelink1{
   background-color:black;
   width: 200px;
   height: 50px;
   padding: 10px;
   color: white;
   margin-left: 0%;
   margin-right: 0%;
}
H2{
   margin-top: 30PX;
   margin-bottom: 20PX;
}
ul{
   list-style: none;
   width: 100%;
}
li{
   padding-top: 10px;
   padding-bottom: 10px;
   width: 100%;
}
.logo{
   margin-left: 10%;
   margin-right: auto;
}
.linkitem{
   padding-left: 10%;
   width: 200px;
   color: white;
}
.linkitem1{
   margin-left: 10%;
   
}
.linkitem a{
   color: white;
}
.toggle1{
   cursor: pointer;
}
.slide1{
  
   width: 200px;
   color: #FFDEAD;
   
}
.slide1 a{
   color: white;
   width: 200px;
   padding-right: 150px;
   padding-left: 30px;
   padding-top: 10px;
   padding-bottom: 10px;
}
.sideicon{
   position: absolute;
   right: 2px;
   
}
.slide2{
   background-color: #123456;
   width:100%
}
.slide2 li{
  width: 400px;
}
.innermenu{
   
   width: 200px;
}
.sidenav.active {
   left: 0;
   opacity: 1;
   transition: all 0.5s ease;
   z-index: 1;
   height: 100%;
 }
 .newbtn{
   cursor: pointer;
 }
 .closebtn1{
      font-size: 50px;
      display: none;
      color: #1E90FF;
      position: absolute;
      right: 2px;
   }
   body{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
   }
   .border2
  {
 border: 1px solid grey;
    height: 25px;
    margin-top: 5px;
   border-radius: 5px;
   
  }
  .support1{
   position: absolute;
   left: 20px;
   bottom: 50px;
  }
  .spinner{
   width: 50px;

 padding-right: 1px;
   margin-left: -20px;
   margin-top: -10px;
  }
  .sendbtn{
   padding-right: 5px;
   margin-top: 15px;
  }
  .whitecolor{
   color: white;
   margin-left: 20%;
  }
  .rowbtn{
   padding-bottom: 5px;
  }
 
@media screen and (max-width: 960px) {
   .sidenav{
       position: relative;
       left: -100%;
       width: 65%;
      
   }
   .closebtn1{
     display: block; 
   }
   .content{
      position: absolute;
      left:-40px;
      top:50px;
   }
   .sidenav.active {
      left: 0;
      top: 60px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 99;
      height: 100%;
    }
    .sidenav::-webkit-scrollbar{
     display: none;
      }
      .toplogo{
         background-color: white;
         height: 60px;
         display: flex;
         justify-content: center;
         padding-bottom: 50px;
        
      }
      .toplogo1{
         background-color: white;
         height: 60px;
         display: flex;
         justify-content: center;
         padding-bottom: 50px;
         width: 200px;
         display: none;
      }
}