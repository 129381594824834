.whitelabel{
    border-color: white;
}
.spinnerdiv{
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
.box{
    display: flex;
    flex-wrap: nowrap;
}
.bg-grey{
    background-color: rgb(175, 172, 172);
    margin-left: 0px;
    padding-left: 0px;
    width: 100%;
}