@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
/* styling scrollbars*/

  /*general syling*/
*{
  margin: 0;
  padding: 0;
}
body{
  box-sizing: border-box;
  font-family: "Source Sans Pro";
  padding:0;
    margin: 0%;
    clear: both;
}
body {
  overflow-x: hidden;
  max-width: 100%;
}

.text-primary-p{
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}
.font-bold{
  font-weight: 700;
}
.text-title{
  color: #2e4a66;
}
.text-lightblue{
  color: #469cac;
}
.text-red{
  color: red;
}
.text-yellow{
  color: yellow;
}
.text-green{
  color: rgb(206, 219, 206);
}
.container-fluid{
  display: grid;
  height: 100vh;
  grid-template-columns: 0.6fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas: 
  "sidebar nav nav nav"
  "sidebar main main main";
}
@media screen and (max-width: 978px){
 .container{
     grid-template-columns: 1fr;
     grid-template-rows: 0.2fr 3fr;
     grid-template-areas: 
     "nav"
     "main";
 } 
}
.navbar{
    background-color: white;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0px 0 0px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  top: 0;
  width: 100%;
  left:0px;
  z-index: 1;
  background-image: linear-gradient(to right,#2b80ec,#1d1f33);

}
.dontwrap{
    display: flex;
    flex-wrap: nowrap;
}
#divToPrint2{
    min-width: 300px;
    max-height: 500px;
    font-size: 16px;
}
.offline{
    position: absolute;
    top: 35px;
   right: 0px;
    background-color: rgb(236, 188, 188);
    border-radius: 5px;
    padding: 4px;
    border: 1px solid rgb(179, 43, 43);
    font-size: 15px;
    width: 165px;
    font-family: 'Times New Roman';
}
.req{
    position: absolute;
    top: -10px;
    left: -50px;
    color: #ffffff;
   display: none;
}
.mgn:hover .req{
    display: block;
}
.wticon{
    color: #fff;
}
.searchwidth{
    width: 100%;
}
.powered{
    font-size: 9px;
}
.noti{
    position: absolute;
    margin-right: 100px;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    padding-left: 5px;
    width: 15px;
    height: 15px;
}
.product3{
    font-size: 100px;
    color: red;
    color: #fff;
    background-color: #5cb85c;
}
.iconb{
   display: flex;
   justify-content: center;
}
.navbtn{
    height: 50px;
    cursor: pointer;
    margin: -5px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #5cb85c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-color: #2dce89;
    
}

.navbar1{
    background-color: white;
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  top: 0;
  width: 100%;
  left:0px;
  z-index: 1;
  background-image: linear-gradient(to right,#2b80ec,#1d1f33);

}
.usericon{
    font-size: 18px;
}
.mobilebtn{
    color: white;
    font-size: 25px;
}
.nav_icon{
    display: none;
}
.nav_icon > i{
    font-size: 26px;
    color: #a5aaad;
}
.navbar_left > a{
margin-right: 30px;
text-decoration: none;
color: #a5aaad;
font-size: 15px;
font-weight: 700;
}
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_left.active_link{
   color: #265acc;
   border-bottom: 3px solid #265acc;
   padding-bottom: 12px;
    }
.navbar_right{
        display: flex;
       position: absolute;
       right: 250px;
        }
        .navbar_right1{
            display: flex;
            align-items: center;
           position: fixed;
           right: 10px;
           color: white;
           
            }
 .navbar_right a{
        margin-left: 20px;
        text-decoration: none;
}
.navbar_right2{
    display: flex;
   position: absolute;
   left: 20%;
   color: white;
   text-align: center;
   align-items: center;
   width: 100%;
   padding-top: 5%;
   z-index: -1;
    }
.navbar_right a i{
    font-size: 16px;
    border-radius: 50px;
    color: #fff;
    background-color: #2dce89;
    box-shadow: 2px 2px 5px #d9d9d9 -2px -2px 5px #ffffff;
    padding: 7px;
}
.adminmenu1{
    box-shadow: #0000003d 0px 3px 8px;
   padding: 4px;
    position: fixed;
    right: 15px;
    top: 60px;
    background-color: white;
    height: 100px;
    border-radius: 5;
}
.setmyw{
    width: 300px;
    font-family: Tahoma;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
margin-left: auto;
margin-right: auto;
text-align: center;
}
.adminactive{
    display: none;
    background-color: black;
    padding-bottom: 2px;
}
@media screen and (max-width: 978px){
    .nav_icon{
       display: inline;
    } 
 }
 @media screen and (max-width: 960px){
    .navbar_left{
       display: none;
    } 
    .navbar{
        background: #1E90FF;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-bottom: 1px solid lightgrey;
        position: fixed;
      top: 0;
      width: 100%;
      left:0px;
      margin-bottom: 20px;
    }
    .navbar1{
        background: #1E90FF;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-bottom: 1px solid lightgrey;
        position: fixed;
      top: 0;
      width: 100%;
      left:0px;
      margin-bottom: 20px;
    }
   
    .navbar_right{
        display: flex;
       position: absolute;
       right: 5%;
        }
        .navbar_right1{
            display: flex;
           position: absolute;
           right: 5%;
            }
        .usericon{
            font-size: 18px;
            color: white;
        }
        .navbar_right{
           display: none;
            }
            .navbar_right2{
                display: flex;
               position: absolute;
               left: 20%;
               top: 5%;
               color: white;
               align-items: center;
               width: 100%;
               padding-top: 5%;
               z-index: -1;
                }
 }
.sidenav{
  
   width: 230px;
   box-shadow: #0000003d 0px 3px 8px;
   position: fixed;
   z-index: 99; 
   margin-left: 0%;
   color: white;
  top: 60px;
  min-height:100%;
   background-color: rgb(0, 21, 41);
   overflow-y:scroll;
    overflow-x:hidden;
    bottom:0;
  
}
.sidenav::-webkit-scrollbar-thumb:hover{
   background-color: grey;
border-radius:5px;
}
.sidenav::-webkit-scrollbar{
   width:10px;
   background-color: rgb(0, 21, 41);
   }
.toplogo{
   background-color: white;
   height: 60px;
   display: flex;
   justify-content: center;
   padding-bottom: 50px;
   display: none;
}
.toplogo1{
   background-color: white;
   height: 60px;
   display: flex;
   justify-content: center;
   padding-bottom: 50px;
   width: 230px;
}
.sidenav1{
  
   min-width: 200px;
   box-shadow: #0000003d 0px 3px 8px;
   position: fixed;
   z-index: 99; 
   margin-top: 0%;
   margin-left: 0%;
   color: white;
  top: 200;
  height:100%;
   background-color: rgb(0, 21, 41);
   overflow-y:scroll;
    overflow-x:hidden;
    bottom:0;
    display: none;
}
a{
   color:white
}
.mkwhite{
   color: white;
}
.jimslogo{
   padding-left: 13%;
   
   width: 200x;
}
.toggle1{
   margin-right: 50px;
}
.whiteicon{
   color: white;
}
.content{
   position: absolute;
   left:70px;
   top:50px;
}
.submenu{
   display: block;
   transition: 0.5s all;
}
.submenuhidden{
   display: none;
}
.invoice{
   cursor: pointer;
}
.updownicons{
   font-size: 15px;
}
a{
   text-decoration: none;
   color: black;
}
i{
   margin-right: 10px;
}
.activelink{
   background-color:#1E90FF;
   width: 100%;
   color: white;
   margin-left: 0%;
   margin-right: 0%;
}
.activelink1{
   background-color:black;
   width: 200px;
   height: 50px;
   padding: 10px;
   color: white;
   margin-left: 0%;
   margin-right: 0%;
}
H2{
   margin-top: 30PX;
   margin-bottom: 20PX;
}
ul{
   list-style: none;
   width: 100%;
}
li{
   padding-top: 10px;
   padding-bottom: 10px;
   width: 100%;
}
.logo{
   margin-left: 10%;
   margin-right: auto;
}
.linkitem{
   padding-left: 10%;
   width: 200px;
   color: white;
}
.linkitem1{
   margin-left: 10%;
   
}
.linkitem a{
   color: white;
}
.toggle1{
   cursor: pointer;
}
.slide1{
  
   width: 200px;
   color: #FFDEAD;
   
}
.slide1 a{
   color: white;
   width: 200px;
   padding-right: 150px;
   padding-left: 30px;
   padding-top: 10px;
   padding-bottom: 10px;
}
.sideicon{
   position: absolute;
   right: 2px;
   
}
.slide2{
   background-color: #123456;
   width:100%
}
.slide2 li{
  width: 400px;
}
.innermenu{
   
   width: 200px;
}
.sidenav.active {
   left: 0;
   opacity: 1;
   transition: all 0.5s ease;
   z-index: 1;
   height: 100%;
 }
 .newbtn{
   cursor: pointer;
 }
 .closebtn1{
      font-size: 50px;
      display: none;
      color: #1E90FF;
      position: absolute;
      right: 2px;
   }
   body{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
   }
   .border2
  {
 border: 1px solid grey;
    height: 25px;
    margin-top: 5px;
   border-radius: 5px;
   
  }
  .support1{
   position: absolute;
   left: 20px;
   bottom: 50px;
  }
  .spinner{
   width: 50px;

 padding-right: 1px;
   margin-left: -20px;
   margin-top: -10px;
  }
  .sendbtn{
   padding-right: 5px;
   margin-top: 15px;
  }
  .whitecolor{
   color: white;
   margin-left: 20%;
  }
  .rowbtn{
   padding-bottom: 5px;
  }
 
@media screen and (max-width: 960px) {
   .sidenav{
       position: relative;
       left: -100%;
       width: 65%;
      
   }
   .closebtn1{
     display: block; 
   }
   .content{
      position: absolute;
      left:-40px;
      top:50px;
   }
   .sidenav.active {
      left: 0;
      top: 60px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 99;
      height: 100%;
    }
    .sidenav::-webkit-scrollbar{
     display: none;
      }
      .toplogo{
         background-color: white;
         height: 60px;
         display: flex;
         justify-content: center;
         padding-bottom: 50px;
        
      }
      .toplogo1{
         background-color: white;
         height: 60px;
         display: flex;
         justify-content: center;
         padding-bottom: 50px;
         width: 200px;
         display: none;
      }
}
.ordersnumber{
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  .row{
    margin-top: 0%;
    padding-top: 0%;
  }
  .divwrap{
    display: flex;
    flex-wrap: nowrap;
  }
  .floatright{
    float: right;
    margin-right: 10%;
  }
  .now{
    
    margin-top: 0%;
    padding-top: 0%;
    font-weight: bold;
    border-bottom: 1px solid grey;
  }
  .newwidth{
    width: 220px;
  }
  .totalborder{
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  .blackme{
    background-color: black;
  }
  .bodysection{
    margin-bottom: 100px;
    position:relative;
    top: 10%;
    left: 120px;
    
    margin-top:1%;
    background-color: white;
    padding: 0;
    clear: both;
    box-shadow: 0 3px 10px;
    border-radius: 0.25rem;
  }
  .bodysection3{
    position: relative;
    top: 10%;
    left: 2%;
    margin-left:7%;
    margin-top:2%;
    padding-left: 2%;
    margin-bottom: 5%;
    clear: both;
    margin-bottom: 100px;
  }
  .bodysection4{
    clear: both;
    position: relative;
    top: 10%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
  }
  .bodysection2{
    position: fixed;
    overflow-y: auto;
    top: 15%;
    left: 14%;
    margin-left:5%;
    margin-top:1%;
    clear: both;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
   
  }
  .bodysection7{
    position: fixed;
    height: 280px;
    top: 15%;
    left: 14%;
    margin-left:5%;
    margin-top:1%;
    clear: both;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
   
  }
  .bodysection6{
    position: relative;
    top: 7%;
    left: 4%;
    margin-left:5%;
    margin-top:1%;
    clear: both;
    padding: 0;
    margin-bottom: 5%;
    box-shadow: 0 3px 10px;
    bottom:0;
   
  }
  .bodysection8{
    position: relative;
    top: 7%;
    clear: both;
    margin-left:-8%;
    margin-top:1%;
    background-color: white;
    padding: 0;
    margin-bottom: 5%;
    bottom:0;
    margin-bottom: 100px;
  }
  .bodysection80{
    position: relative;
    top: 7%;
    clear: both;
    margin-left:-110px;
    padding-left: -8%;
    margin-top:1%;
    background-color: white;
    padding: 0;
    margin-bottom: 5%;
    bottom:0;
    margin-bottom: 100px;
  }
  .cardheight{
    height:100px
  }
  .productparent{
   margin-right: 10%;
  }
  .textalign{
    text-align: right;
  }
  hr {
    border: none;
    border-top: 1px dashed black;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 100%;
  }
  .text-alignme{
    text-align: right;
  }
  .productsquare{
    cursor: pointer;
    max-width: 20%;
    min-width: 135px;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2px;
    margin-left: 2px;
    flex: 1 1;
    text-align: center;
  }
  .productouter{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .productname{
    
   width: 100%;
   display: flex;
    justify-content: center;
  }
  .rmleft{
    padding-right: 25px;
  }
  .nosize{
    max-width: 6px;
  }
  .newdiv{
    background-color: red;
    padding-left: 0px;
    margin-left: 0px;
  }
  .product{
    font-size: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .product2{
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .product3{
    font-size: 20px;
    width: 100%;
    display: flex;
    height: 20px;
    justify-content: center;
    color: red;
  }
  .divborder1{
    border: 1px solid #E5E4E2;
   
  }
  .ldiv{
    border-right: 1px solid grey;
  }
  .cborder{
   border: none;
   background-color: transparent;
  }
  .bborder{
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }
  .minqty{
    min-width: 100px;
  }
  .product1{
font-size: 25px;
  }
  .price{
   
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .path{
    position: relative;
    top: 25%;
    left: 9%;
    margin-top: 2%;
  }
  .bodysection1{
    position: absolute;
    top: 15%;
    left: 7%;
    margin-left:5%;
   
  }
  .orderscol{
    box-shadow: #0000003d 0px 3px 8px;
    border-radius: 5px;
    height: 100px;
    margin-left: 2%;
    margin-top: 10px;
    max-width: 220px;
    background-color: white;
   
  }
  .salesdiv{
    border-left: 3px solid #72A0C1;
  }
  .salestext{
    color:#72A0C1 ;
  }
  .stockdiv{
    border-left: 3px solid #1560bd;
  }
  .stocktext{
    color:#1560bd ;
  }
  .usersdiv{
    border-left: 3px solid #1034A6;
  }
  .userstext{
    color:#1034A6 ;
  }
  .itemsdiv{
    border-left: 3px solid #008E97;
  }
  .itemstext{
    color:#008E97 ;
  }
  .orderscol1{
    box-shadow: #0000003d 0px 3px 8px;
    border-radius: 5px;
    height: 100px;
    margin-left: 2%;
    margin-top: 10px;
    min-width: 100px;
    background-color: white;
  }
  .ordersrow{
width: 175px;
margin-left: 10px;
padding-bottom: 5px;
  }
  .ordersrow1{
    min-width: 175px;
    margin-left: 10px;
    padding-bottom: 5px;
      }
  .col1{
    max-width: 200px;
  }
  .submenu{
    background-color: grey;
    padding: 5px;
  }
  .invdetails{
    display: flex;
    justify-content: space-between;
    
  }
  .rowbd{
    border: 1px solid grey;
    display: flex;
    flex-wrap: nowrap;
  }
  .rowbd1{
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
  }
  .rowbd2{
    border-bottom: 1px solid grey;
   
  }
  .allcont{
    border: 1px solid grey;
  }
  .printme {
    display: none;
  }
  @media print {
    .no-printme  {
      display: none;
    }
    .printme  {
      display: block;
    }
  }
  .testmenow{
    background-color: black;
    z-index: -1; 
  }
  .stbg{
    background-color: #72A0C1;
    color: white;
    font-size: 35px;
    margin-left: 2px;
  }
  .stbg1{
    background-color: #1560bd;
    color: white;
    font-size: 35px;
    margin-left: 2px;
  }
  .stbg2{
    background-color: #008E97;
    color: white;
    font-size: 35px;
  }
  .stbg3{
    background-color: #1034A6;
    color: white;
    font-size: 35px;
  }
  .dots{
    padding: 3px;
    height: 28px;
    display: flex;
    align-items: center;
   margin-top: 3px;
   cursor: pointer;
   position: -webkit-sticky;
   position: sticky;
  }
  .wrapper {
    position: relative;
  }
  .fixwidth{
    height: 30px;
    float: right;
}
.fixwidth1{
  height: 30px;
  padding-top: 2px;
}
.solo{
  display: block;
  margin-top: -10px;
}
.setbg{
  background-color: rgb(0, 21, 41);
  color: white;
}
.dotsdiv{
  box-shadow: #0000003d 0px 3px 8px;
  position: absolute;
  right: 0px;
  top: 22px;
  height: 100px;
  display: none;
}
.dotsdivactive{
  
  position: absolute;
  right: -20px;
  top: -3px;
 
  height: 100px;
  display: block;
}
.ulpadding{
  padding: 3px;
  text-decoration: none;
  cursor: pointer;
}
.uldiv{
  padding: 3px;
  text-decoration: none;
  box-shadow: #0000003d 0px 3px 8px;
  background-color: #ffffff;
  position: relative;
  right: 0px;
  top: -3px; 
  cursor: pointer;
  width: 100px;
  z-index: 10;
  text-decoration: none;
  box-shadow: #0000003d 0px 3px 8px;
}
.uldiv:hover{
  cursor: pointer;
}
.theader{
 
  background-color:#72A0C1 ;
}
  @media screen and (max-width: 960px) {
    .bodysection3{
      position: relative;
      top: 10%;
      left: -7%;
      margin-left:3%;
      margin-top:2%;
      padding-left: 2%;
      margin-bottom: 10%;
     
    }
    .bodysection1{
      position: absolute;
      top: 12%;
      left: -5%;
      margin-left:5%;
     
    }
    .bodysection{
      position: relative;
      top: 10%;
      clear: both;
      left: 0%;
      margin-left:0%;
    padding-left: 0%;
    margin-bottom: 100px;
    }
    
    .qtysize{
      width: 10px;
    }
    
    .bodysection6{
      position: relative;
      top: 10%;
      left: 0%;
      margin-left:0%;
    padding-left: 0%;
    }
    .bodysection2{
  
      margin-left:-10%;
      padding-left: 0%;
      width: 100%;
    }
    .bodysection2 input{
  
     width: 100%;
    
    }
    .mobilecenter{
      margin: 0 auto;
     text-align: center;
    }
    .mobile{
      display: none;
    }
    .mobilediv{
      padding-right: 20px;
    }
    .orderscol{
      box-shadow: #0000003d 0px 3px 8px;
      border-radius: 5px;
      height: 100px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      min-width: 300px;
    }
    .ordersrow{
      width: 100%;
      display: flex;
     justify-content: center;
     margin-right: 3px;
    }
    .orderscol1{
      box-shadow: #0000003d 0px 3px 8px;
      border-radius: 5px;
      height: 100px;
      
      margin-top: 10px;
      min-width: 300px;
    }
    .ordersrow1{
      width: 100%;
      margin-left: 0px;
    }
    .bodysection4{
      background-color: white;
      position: relative;
      top: 15%;
      left: 1%;
      margin-left:-1%;
      margin-top:2%;
      padding-left: 2%;
      margin-bottom: 5%;
     
    }
    .card-deck{
      justify-content: center;
      background-color: #D6EAF8;
      width: 100%;
    }
    .card{
      justify-content: center;
    }
    .theader{
      margin-top: 5%;
     
    }
    .productsquare{
      cursor: pointer;
      min-width: 125px;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2px;
      margin-left: 2px;
      flex: 1 1;
    }
    .salesdiv{
      border-left: 4px solid #72A0C1;
    }
    .salestext{
      color:#72A0C1 ;
    }
    .stockdiv{
      border-left: 4px solid #1560bd;
    }
    .stocktext{
      color:#1560bd ;
      
    }
    .usersdiv{
      border-left: 4px solid #1034A6;
    }
    .userstext{
      color:#1034A6 ;
    }
    .itemsdiv{
      border-left: 4px solid #008E97;
    }
    .itemstext{
      color:#008E97 ;
    }
    .bodysection80{
      position: relative;
      top: 7%;
      clear: both;
      margin-left:-8%;
      margin-top:1%;
      background-color: white;
      padding: 0;
      margin-bottom: 5%;
      bottom:0;
      margin-bottom: 100px;
    }
 }
.row{
    margin-bottom: -10px;
   
}
@media screen and (max-width: 960px) {
    .staffpadding{
        padding-left: 4%;
    }
}
.studenthealth{
position: absolute;
left: 1px;
margin-bottom: 2px;
}
@media print {
  .dontprint {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}


.printView {
  visibility:visible;
}

.printing .printView {
  /* You can have any CSS here to make the view better on print */
  position:absolute;
  top:0;
}
.newselect{
    height: 35px;
    width: 100%;
    border: none;
    margin-top: 0%;
}
.newselect1{
    height: 35px;
    width: 100%;
    
}
.modalwidth{
  width: 400px;
}
.Tabs {
    width: 70%;
    height: auto;
 
    padding: 2rem 1rem;
   
    @media (max-width:  480ppx) {
     padding: 2rem 0;
     width: 100%;
    }
   }

   /* Tab Navigation */
ul.nav {
    width: 40%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #39A2DB;
    border-radius: 2rem;
    
    padding-left: 0px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  ul.nav li:first-child {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }
   
  ul.nav li:last-child {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  ul.nav li {
    width: 32%;
    padding-bottom: 10%;
    height: 30px;;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    @media (max-width: 768px) {
        width: 90%;
      }
  }
  ul.nav li:nth-child(2) {
    border-radius: 0;
   
  }
  ul.nav li:hover {
    background: rgba(50, 224, 196, 0.15);
  }
  ul.nav li.active {
    background: #39A2DB;
  }

  /* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 10px;
}

.mtop{
   
}
.testme{
    border-radius: 0px;
    -webkit-appearance: none;
}
.success{
    z-index: 1;
    position: absolute;
    right: 8%;
    top: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color:#4FBF26;
    color: white;
    height: 45px;
    width: 190px;
    font-size: 12px;
    border-radius: 5px;
}
.errmessage{
    color: red;
    
}
.errmessagehide{
    color:white;
   
    padding: 0;
}
.errmessage1{
  color: red;
  margin-bottom: -8%;
}
.errmessagehide1{
  color:white;
  margin-bottom: -8%;
  padding: 0;
}
.errmessagehide4{
  color:#85C1E9;
  margin-bottom: -8%;
  padding: 0;
}
.errmessagehide2{
  color:white;
  margin-bottom: -8%;
  padding: 0;
}
.errmessage3{
  color: red;
  margin-bottom: -12%;
}
.errmessagehide3{
  color:white;
  margin-bottom: -12%;
  padding: 0;
}
.errorborder{
border-color: red;

}
.row{
    margin-bottom: -20px;
   
}
.dontshow{
  display: hidden;
 
}
.select input {
    font-family: "Times New Roman";
  }
body {
    height: 100%;
    top: 0;
    bottom:0;
    overflow-y:scroll;
    overflow-x:hidden;
    font-size: 18px;
}
.datewidth{
    
}
.successhide{
    display: none;
    z-index: 1;
    position: absolute;
    right: 8%;
    top: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color:#4FBF26;
    color: white;
    height: 45px;
    width: 190px;
    font-size: 12px;
    border-radius: 5px;
}
.succcessicon{
    color: white;
    font-size: 25px;
}
@media screen and (max-width: 960px) {
    .studenthealth{
        position: absolute;
        left: -8px;
        margin-bottom: 2px;
        width: 70%;
        }
        .Tabs{
          width: 100%;
        }
        .btnmenu{
          margin: 2px;
        }
        .errmessage3{
          color: red;
          margin-bottom: -12%;
        }
        .errmessagehide3{
          color:white;
          margin-bottom: -12%;
          padding: 0;
        }
}
.loginscreen{
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    background-color: #fefefe;
    margin: 5% auto 15% auto;
    border: 1px solid #888;
    font-size: 17px;
    width: 50%;
}
.title{
    margin-left: auto;
  margin-right: auto;
  width: 150px;
  color:#1E90FF
}
.primary{
    width: 100%;
    background-color: #1E90FF;
}
.footer{
width: 100%;
padding-bottom: 10px;
}
@media screen and (max-width: 960px) {
    .loginscreen{
width: 80%;
margin-top:20%;

    }

}
.marginleft{
    
    padding-bottom: 300px;
    
  }
.btn1{
    cursor: pointer;
    margin: 4px;
    padding: 0px;
    font-size: 14px;
  }
  .centermodal{
    margin-left: auto;
    margin-right: auto;
    width: 100%;

  }
  .modal1{
    width:50%;
    font-size: 18px;
    height: 450px;
    margin: 0 auto; 
    border-radius: 0%;
  }
  .check{
    margin: auto;
  }
  .progress{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    font-size: 12px;
    background-color: white;
    height: 30px;
    text-align: center;
    margin-bottom: 5px;
  }
  .modalheader{
    font-size: 12px;
    height: 20px;
   
  }
  .modalfooter{
    font-size: 12px;
    height: 10px;
  }
  .closebtn{
    height: 20px;
    width: 45px;
    font-size: 12px;
    padding-top: 0%;
    padding-left: 2%;
    margin-right: 1px;
    position: absolute;
    right: 1px;
   
  }
  .modalheader1{
    border: none;
    font-size: 12px;
    height: 20px;
  }
  .modalbody{
   
    font-size: 12px;
  }
  .green{
    color: green;
    font-size: 30px;
  }
  .red{
    color: red;
    font-size: 30px;
  }
  .border
  {
    border-left: 1px solid black;
    
  }
  .border1
  {
   border: none;
   padding-top: 1px;
    
  }
  
.label2{
margin-top: -25px;

}
.bbottom{
    border-bottom: 1px solid black;
}
.whitelabel{
    border-color: white;
}
.spinnerdiv{
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
.box{
    display: flex;
    flex-wrap: nowrap;
}
.bg-grey{
    background-color: rgb(175, 172, 172);
    margin-left: 0px;
    padding-left: 0px;
    width: 100%;
}
.btn1{
    cursor: pointer;
    margin: 4px;
    padding: 0px;
  }
  .centermodal{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .centermodal1{
    margin-left: auto;
    margin-right: auto;
    width: 322px;
    overflow: hidden !Important;
   
  }
  .centermodal2{
    margin-left: auto;
    margin-right: auto;
    width: 450px;
    overflow: hidden !Important;
   
  }
  .modal-body1{
    overflow:auto;
   
    }
  .modal2{
    width:250px;
    font-size: 12px;
    height: 350px;
    margin: 0 auto; 
  }
  .searchlft{
    position: relative;
    margin-left: -40px;
    margin-right:10px ;
  }
  .check{
    margin: auto;
  }
  .progress{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    font-size: 12px;
    background-color: white;
    height: 30px;
    text-align: center;
    margin-bottom: 5px;
  }
  .modalheader{
    font-size: 12px;
    height: 20px;
   
  }
  .modalfooter{
    font-size: 12px;
    height: 10px;
  }
  .closebtn{
    height: 20px;
    width: 45px;
    font-size: 12px;
    padding-top: 0%;
    padding-left: 2%;
    margin-right: 1px;
    position: absolute;
    right: 1px;
   
  }
  .modalheader1{
    border: none;
    font-size: 12px;
    height: 20px;
  }
  .modalbody1{
    height: 80px;
    font-size: 12px;
  }
  .green{
    color: green;
    font-size: 30px;
  }
  .red{
    color: red;
    font-size: 30px;
  }
  .border
  {
    border-left: 1px solid black;
    
  }
  .border1
  {
   border: none;
   padding-top: 1px;
    
  }
  
.container {
    
    background-color: #eeeded;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .tab-list {
    height: 40px;
    display: flex;
    list-style: none;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  
  .tabs {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #80808013;
    position: relative;
    cursor: pointer;
   
  }
  
  .tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .active-tabs {
    background-color: #85C1E9;
    
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 4px;
    background: #5893f1;
  }
  
  .content1 {
      display: none;
      background-color: #85C1E9;
      padding: 10px;
  }
  
  .active-content {
      display: flex;
  }
.footer{
    background-color: rgb(250, 246, 246);
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  bottom: 0;
  width: 90%;
  left:200px;
  z-index: 1;
  margin-top: 50px;
  clear: both;
}
.footer1{
    background-color: rgb(209, 204, 204);
    grid-area: nav;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid lightgrey;
    position: fixed;
  bottom: 0;
  width: 100%;
  left:0px;
  z-index: 1;
  margin-top: 50px;
  clear: both;
}
.text-color{
    color: #1560bd;
}
.navbar_rightnew{
        display: flex;
     align-items: center;
     padding-top: 10px;
       position: absolute;
       right: 30%;
        }
        .navbar_rightnew1{
          display: flex;
       align-items: center;
       padding-top: 10px;
         position: absolute;
        right: 10%;
          }
 .navbar_right a{
        margin-left: 20px;
        text-decoration: none;
}
.navbar_right a i{
    color: #f3f3f3;
    font-size: 16px;
    border-radius: 50px;
   
    box-shadow: 2px 2px 5px #d9d9d9 -2px -2px 5px #ffffff;
    padding: 7px;
}
.adminmenu{
    box-shadow: #0000003d 0px 3px 8px;
    padding: 10px;
    position: absolute;
    right: 100px;
    top: 55px;
}
.adminactive{
    display: none;
    background-color: black;
}
@media screen and (max-width: 978px){
    .nav_icon{
       display: inline;
    } 
 }
 @media screen and (max-width: 960px){
    .navbar_left{
       display: none;
    } 
    .footer{
       margin-top: 5%;
        grid-area: nav;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
        border-top: 1px solid lightgrey;
        position: fixed;
      bottom: 0;
      width: 100%;
      left:0px;
      
    }
    .adminmenu{
        box-shadow: #0000003d 0px 3px 8px;
        padding: 10px;
        position: absolute;
        right: 30px;
        top: 55px;
        background-color: white;
    }
    .navbar_rightnew{
       display: inline;
       width: 100%;
       left:0px;
        }
        .usericon{
            font-size: 18px;
            color: white;
        }
        .text-color{
            display: none;
        }
        .footer1{
            background-color: rgb(209, 204, 204);
            grid-area: nav;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px 0 30px;
            border-bottom: 1px solid lightgrey;
            position: fixed;
          bottom: 0;
          width: 100%;
          left:0px;
          z-index: 1;
          margin-top: 80px;
          clear: both;
        }
 }
